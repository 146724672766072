/*!

=========================================================
* Material Dashboard PRO React - v1.8.0 Based on Material Dashboard PRO v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// variables and mixins
@import "material-dashboard-pro-react/variables";
@import "material-dashboard-pro-react/mixins";
@import "material-dashboard-pro-react/shadows";

// plugin css
@import "material-dashboard-pro-react/plugins/plugin-nouislider";
@import "material-dashboard-pro-react/plugins/plugin-perfect-scrollbar";
@import "material-dashboard-pro-react/plugins/plugin-react-datetime";
@import "material-dashboard-pro-react/plugins/plugin-react-bootstrap-sweetalert";
@import "material-dashboard-pro-react/plugins/plugin-react-chartist";
@import "material-dashboard-pro-react/plugins/plugin-react-big-calendar";
@import "material-dashboard-pro-react/plugins/plugin-react-jvectormap";
@import "material-dashboard-pro-react/plugins/plugin-react-table";
@import "material-dashboard-pro-react/plugins/plugin-react-tagsinput";
@import "material-dashboard-pro-react/plugins/plugin-react-tags";

// Core CSS
@import "material-dashboard-pro-react/misc";
@import "material-dashboard-pro-react/fileupload";
@import "material-dashboard-pro-react/fixed-plugin";

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button:hover > .MuiListItemAvatar-root > .MuiBadge-root > img {
  //transform: scale(1.1);
}

.rcw-widget-container {
  transition: .3s;
  z-index: 10;
}

.rcw-widget-container:hover {
  //transform: scale(1.1);
}

.swal2-container.swal2-center {
  z-index: 10000;
}

.rce-mbox {
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, .2) !important;
  max-width: 50%;
  border-radius: 0 25px 25px 25px !important;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.rce-mbox-right {
  //max-width: 100%;
  float: right;
  //background: linear-gradient(90deg, #c1eeff, #a5e6fa) !important;

  border-radius: 25px 0 25px 25px !important;
  /* background: linear-gradient(90deg, #FF5824, #EF3F7A) !important; */
  color: #FFF;
  //background: rgb(233, 77, 63) !important;
  background: linear-gradient(45deg, #252525, #444444) !important;
}

.rce-mbox-right-notch {
  fill: #a5e6fa !important;
  display: none !important;
}

.rce-mbox-left-notch {
  display: none !important;
}

.rce-container-mbox {
  width: inherit;
}

.rce-container-mbox.has-reactions {
  width: inherit;
  padding-bottom: 20px;
  overflow: hidden;
}

.rce-smsg-warning > .rce-smsg {
  background: linear-gradient(60deg, #efa545, #ffb664) !important;
}

.rce-smsg {
  width: 100%;
  max-width: calc(100% - 20px) !important;
  box-shadow: 0 0 10px -5px #00bfd4 !important;
  border-radius: 5px !important;
  background: linear-gradient(60deg, #0098DA, #00a1e4) !important;
  color: white !important;
  font-family: Asap;
  text-size: 11px;
  margin-bottom: 20px !important;
  height: 25px !important;
}

.rce-smsg-date > .rce-smsg {
  width: 150px !important;
  height: 30px !important;
  background: linear-gradient(60deg, #FFF, #EEE) !important;
  box-shadow: 0 0 25px -5px #777 !important;
  color: #3C4858 !important;
  font-weight: bold;
  font-family: "Segoe UI", "Helvetica Neue", "Helvetica", "Lucida Grande", Arial, "Ubuntu", "Cantarell", "Fira Sans", sans-serif;
}

.rce-mbox-text {
  white-space: pre-wrap;
  text-align: left;
}

.rce-mbox-photo--img img {
  width: 100%;
}

.rce-mbox-right .rce-mbox-file button {
  //background: #95c4ca !important;
  background: rgb(171 62 52) !important;
  color: #FFF !important;
}

.rce-mbox-right .rce-mbox-file--icon svg, .rce-mbox-right .rce-mbox-file--buttons svg {
  color: #c3f8ff !important;
  //display: none;
}

/* Contêiner de Reações */
.reactions-container {
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: -32px;
  right: 10px;
  z-index: 1;
}

.reaction-container.sent {
  background-color: #1a1a1a;
  border-radius: 50%;
  padding: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.reaction-container.received {
  background-color: #eeeeee;
  border-radius: 50%;
  padding: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.reaction-emoji {
  font-size: 1rem;
}

.reaction-count {
  font-size: 0.85rem;
  color: #555;
}

/* Modal */
.reactions-modal {
  position: absolute;
  top: -60px;
  left: 0;
  width: auto;
  z-index: 999;
}

.reactions-modal-content {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 15px;
  min-width: 250px;
  max-width: 300px;
}

.reactions-modal-header {
  display: flex;
  justify-content: start;
  gap: 15px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.reactions-tab {
  font-size: 0.9rem;
  color: #333;
  font-weight: bold;
}

.reactions-list-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.reaction-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reaction-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.reaction-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.reaction-sender {
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
}

.reaction-action {
  font-size: 0.8rem;
  color: #888;
}

.reaction-emoji-large {
  font-size: 1.5rem;
}

.sc-AxjAm.fnBxmF {
  background-color: #FFF;
}

.imagem-perfil-conexao {
  opacity: 0 !important;
  transition: .3s;
}

.imagem-perfil-conexao:hover {
  opacity: 1 !important;
  //z-index: 10;
}

.rce-mbox:hover > .rce-mbox-body > .rce-mbox-text > #edit-div > #edit-button {
  color: #888 !important;
  opacity: 1;
  z-index: 1;
}

.rce-mbox-time-block {
  background: none;
  color: rgba(0, 0, 0, 0.45);
}

.rce-mbox-forward-left, .rce-mbox-remove-left, .rce-mbox-reply-btn-left {
  color: #444;
}

.rce-mbox-reply-btn-left {
  color: #444;
}

.rce-mbox-reply.rce-mbox-reply-border {
  border-color: #FF3115 !important;
}

.rce-mbox-reply-owner {
  color: #FF3115 !important;
}

.rce-mbox-right > .rce-mbox-body > .rce-mbox-time:before {
  color: #FFFFFF;
}

.rce-mbox-right > .rce-mbox-body > .rce-mbox-title {
  color: #FFFFFF;
  white-space: pre;
}

.rce-mbox-status {
  color: rgba(255, 255, 255, 0.55) !important;
}

.rce-mbox-photo--img__block:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.rce-mbox-photo--img__block {
  transition: 0.2s;
  cursor: pointer;
}

#mais-opcoes-mensagem {
  color: #000;
  position: absolute;
  padding: 0;
  top: 0;
  right: 0;
  transition: .3s;
  opacity: 0;
}

.swal2-container {
  z-index: 10000 !important;
}

.rce-msg-text {
  overflow: hidden !important;
}

.rce-mbox-title:hover {
  border: none;
  text-transform: none;
  text-decoration: none;
}
.rce-mbox-title {
  cursor: auto;
  color: #000;
  font-weight: normal;
}

// **************************
.react-flow__handle {
  width: 10px;
  border: none;
  height: 10px;
  background: #08365A;
}
// **************************
.rce-mbox-file--text {
  text-align: left;
}
.message-notify > .rce-mbox {
  background: linear-gradient(90deg, #4f6975, #708A96);
  //background: linear-gradient(90deg, #754f4f, #967070);
}
.message-notify > .rce-mbox .rce-mbox-text {
  color: #FFFFFF;
}
.message-notify > .rce-mbox .rce-mbox-time:before {
  color: #FFFFFF;
}
// **************************
.inverted-border-radius {
  //position: relative;
  height: 100px;
  width: 5px;
  background-color: #333333;
  border-radius: 25px 25px 25px 0;
}

.inverted-border-radius::before {
  content: "";
  position: absolute;

  background-color: transparent;
  bottom: -50px;
  height: 50px;
  width: 25px;
  border-top-left-radius: 25px;
  box-shadow: 0 -25px 0 0 #333333;
}
// **************************
.react-kanban-board {
  height: calc(100vh - 133px);
}

.react-kanban-column {
  background-color: #f4f5f7 !important;
  border-radius: 15px !important;
  width: 270px !important;

  height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}

.react-kanban-column {

}
// **************************

//@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@font-face {
  font-family: 'NunitoLight';
  src: url('../fonts/Nunito-Light.ttf');
}

@font-face {
  font-family: 'NunitoRegular';
  src: url('../fonts/Nunito-Regular.ttf');
}

@font-face {
  font-family: 'NunitoSemiBold';
  src: url('../fonts/Nunito-SemiBold.ttf');
}

@font-face {
  font-family: 'NunitoBold';
  src: url('../fonts/Nunito-Bold.ttf');
}

@font-face {
  font-family: 'NunitoExtraBold';
  src: url('../fonts/Nunito-ExtraBold.ttf');
}

body {
  min-height: 100vh;
  font-family: 'NunitoRegular', serif;
}

@keyframes blinker {
  from { opacity: 1.0; }
  50% { opacity: 0.3; }
  to { opacity: 1.0; }
}

.react-kanban-column {
  overflow: hidden;
  max-height: calc(100vh - 180px) !important;
  //height: calc(100vh - 180px) !important;
}

/////////////////////////
.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #F5F5F5;
  border-radius: 10px;
  transition: 0.3s;
}

.scroll::-webkit-scrollbar-track:hover {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
}

.scroll::-webkit-scrollbar {
  width: 7px;
  background-color: #F5F5F5;
  margin-left: 2px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(#FF3115, #ED2E5D);
}